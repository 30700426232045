.chess-board {
  width: 70%;
  position: static;
  margin: 0 auto;
  overflow: visible;

  @media screen and (max-width: 480px) and (orientation: portrait) {
    width: 75%;
    @supports (-webkit-touch-callout: none) {
      width: 65%;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 768px) and (orientation: portrait) {
    width: 70%;
    @supports (-webkit-touch-callout: none) {
      width: 60%;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 768px) and (orientation: landscape) {
    width: 60%;
    @supports (-webkit-touch-callout: none) {
      width: 50%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
    width: 60%;
    @supports (-webkit-touch-callout: none) {
      width: 55%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
    width: 60%;
    @supports (-webkit-touch-callout: none) {
      width: 55%;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1280px) and (orientation: landscape) {
    width: 43%;
    @supports (-webkit-touch-callout: none) {
      width: 65%;
    }
  }

  @media screen and (min-width: 1280px) and (max-width: 3840px) and (orientation: landscape) {
    width: 40%;
    @supports (-webkit-touch-callout: none) {
      width: 70%;
    }
  }

  @media screen and (min-width: 3840px) and (orientation: landscape) {
    width: 30%;
    @supports (-webkit-touch-callout: none) {
      width: 90%;
    }
  }


  #myBoard {
    width: 100% !important;

    .board-b72b1 {
      border: 0.4rem solid #f15519;
      outline: 2rem solid #f0e9d0;
      background: red;

      @media screen and (max-width: 480px) {
        border: 0.2rem solid #f15519;
        outline: 2rem solid #f0e9d0;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        border: 0.2rem solid #f15519;
        outline: 2rem solid #f0e9d0;
      }
    }
  }

  .alpha-d2270 {
    color: #0c59a6 !important;
    bottom: -22px !important;
    right: 20px !important;
    font-weight: 600 !important;
    z-index: 2;
  }

  .numeric-fc462 {
    color: #0c59a6 !important;
    font-weight: 600 !important;
    z-index: 2;
    top: 14px !important;
    left: -18px !important;
  }

  .labels {
    color: #0c59a6;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    justify-content: space-around;

    &.alphabets {
      position: relative;
      display: flex;
      z-index: 3;
    }

    &.numbers {
      display: flex;
      margin-left: calc(100% + 0.50vw);
      height: 100%;
      align-items: flex-end;
      justify-content: space-evenly;
      flex-direction: column;
      z-index: 2;

      & > div {
        margin-top: 2.2rem;
      }
    }
  }

  .chess-board-style-default {
    .white-1e1d7 {
      background-color: #f0e9d0;
      color: white;
    }

    .black-3c85d {
      background-color: #0c59a6;
      color: #f0d9b5;
    }

    .piece-417db {
      padding-top: 5px;
      padding-bottom: 2px;
    }
  }

  // // ------------------------------------------------

  .bK-check img[data-piece="bK"] {
    background: red !important;
  }

  .wK-check img[data-piece="wK"] {
    background: red !important;
  }
}
