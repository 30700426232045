.navbar-container {
  background-color: #faf2e5;
  height: 4rem;

  @media screen and (max-width: 480px) {
    display: block;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: block;
    height: 6rem;
  }

  .logo img {
    width: 8rem;
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 10rem;
    }
  }


  .join-btn {
    div {
      background: #FF6B39;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      display: flex;
      border-radius: 3rem;
      font-size: 1.3rem;
      font-weight: 600;
      margin: 1vw;
      padding: 1vw 3vw;
    }
  }

  .content {
    height: 100%;
    padding: 0 2rem;
    margin-left: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      height: 2rem;
    }

    .navbar-btn {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navbar-action {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #cf5060;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        width: 4rem;
        height: 4rem;
      }

      & .img{
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
