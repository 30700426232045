*,
*::after,
*::before {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.25%;
}

.app {
  overflow: hidden;
  height: 100vh;
  background-image: url("./assets/game-bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.t-orange {
  color: #fa958c;
}
