.ludo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  width: 100%;
  outline: none;
  border: none;
  max-width: 20rem;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  /* Desktops and laptops ----------- */
  @media screen and (min-width: 1224px) {
    padding: 1rem 1.2rem;
    font-size: 1.4rem;
  }

  /* Large screens ----------- */
  @media screen and (min-width: 1824px) {
    padding: 0.8rem 1rem;
    font-size: 1.6rem;
  }

  &.prefix {
    justify-content: flex-start;

    .icon {
      line-height: 1;
      height: 2rem;
      margin-right: 1rem;
      margin-left: 2rem;
    }
  }

  @media screen and (min-width: 1224px) {
    width: 110px;
    padding: 5px;
  }

}
