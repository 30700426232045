.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  z-index: 10;
  width: 9vw;
  padding-top: 2rem;
  min-width: 8rem;
  background: #faf2e5;

  @media screen and (max-width: 480px) {
    display: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }

  .sidebar-header {
    display: flex;
    width: 5vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 9vw;
    height: 9rem;

    img {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
  }

  .join-btn {
    background: #FF6B39;
    width: 80%;
    border-radius: 3rem;

    div {
      margin: 1.5rem 4rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      display: flex;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }

  .item {
    cursor: pointer;
    width: 5rem;
    height: 5rem;

    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.logo {
      img {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
    }

    svg {
      fill: white;
      stroke: white;
      color: white;
      font-size: 4rem;
    }

    &:not(.logo) {
      background: #1d4279;
      border-radius: 50%;
      color: white;
    }

    &.exit {
      background: #cf5060;
      border-radius: 50%;

      img {
        width: 40%;
      }
    }
  }
}

.popover {
  .popover-header {
    font-size: 2rem;
    cursor: default;
  }

  .popover-body {
    font-size: 1.5rem;
    cursor: pointer;
  }
}
